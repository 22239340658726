<template>
  <div class="profile-card bg-white rounded-xl">
    <div class="hero rounded-tl-xl rounded-tr-xl relative">
      <div class="absolute left-4 -bottom-8">
        <div class="w-20 h-20 rounded-full bg-white">
          <input style="display: none" accept="image/jpeg, image/png" type="file" ref="photo" @change="selectImage($event)" />
          <User color="neutral-100" height="80" width="80" v-if="userProfile?.student?.photo === '' || userProfile?.student?.photo === undefined" />
          <div v-else class="w-full h-full rounded-full bg-no-repeat bg-cover profpic" :style="`background-image: url(${userProfile?.student?.photo})`"></div>
        </div>
      </div>
    </div>
    <div class="flex flex-col px-4 pb-4 mdmax:p-4 relative">
      <div class="mt-10 mb-4" v-if="userProfile">
        <p class="text-2xl font-semibold mb-3">{{ userProfile.name }}</p>
        <!-- <p class="text-xs mb-4 text-neutral-400">
          Member since <span class="font-semibold">{{ formatDate(userProfile?.student?.createdDate) }}</span>
        </p> -->
        <div class="flex gap-2 items-center mdmax:justify-center">
          <div class="font-semibold mdmax:text-sm"><Mail width="16" /></div>
          <div class="text-xs font-medium">
            {{ userProfile.email }}
          </div>
        </div>
        <div class="flex gap-2 items-center mdmax:justify-center">
          <div class="font-semibold mdmax:text-sm"><Call width="16" /></div>
          <div class="flex mdmax:flex-col">
            <div class="text-xs font-medium">
              {{ userProfile.hp }}
            </div>
          </div>
        </div>
        <div class="mt-4 text-xs bg-neutral-min rounded-md py-2 px-3" :title="userProfile?.student?.description" v-if="userProfile?.student?.description">
          <p class="ellipsis">{{ userProfile?.student?.description }}</p>
        </div>
      </div>
      <template v-else>
        <div class="mt-10 pb-4">
          <div class="h-4 bg-neutral-100 mb-1"></div>
          <div class="h-3 bg-neutral-100 w-1/2 mb-4"></div>
          <div class="h-3 bg-neutral-100 w-2/3 mb-2"></div>
          <div class="h-3 bg-neutral-100 w-2/3 mb-4"></div>
          <div class="h-16 bg-neutral-100 rounded-lg"></div>
        </div>
      </template>
      <ul class="border-t border-neutral-100 pt-4">
        <li></li>
        <li>
          <router-link to="/user/my-classes" class="rounded-lg font-semibold hover:text-yellow transition-all duration-200 flex gap-2 items-center cursor-pointer hover:bg-yellow-100 py-2 px-3">
            <div class="w-7 flex justify-center">
              <Bootcamp width="24" height="24" />
            </div>
            My Class
          </router-link>
        </li>
        <li class="border-t border-neutral-100 mt-4 pt-4">
          <router-link to="/user/profile" class="rounded-lg transition-all font-semibold duration-200 flex gap-2 items-center cursor-pointer hover:bg-yellow-100 py-2 px-3 hover:text-yellow">
            <div class="w-7 flex justify-center">
              <User width="24" height="24" />
            </div>
            Profile
          </router-link>
        </li>
        <li>
          <router-link
            to="/user/change-password"
            class="rounded-lg transition-all duration-200 flex gap-2 font-semibold text-neutral-500 items-center cursor-pointer hover:bg-yellow-100 py-2 px-3 hover:text-yellow"
          >
            <div class="w-7 flex justify-center">
              <Lock />
            </div>
            Change Password
          </router-link>
        </li>
        <li class="border-t border-neutral-100 pt-4 mt-4">
          <div class="rounded-lg transition-all duration-200 flex gap-2 font-semibold text-system-error items-center cursor-pointer hover:bg-yellow-100 py-2 px-3 hover:text-yellow" @click="doLogout">
            <div class="w-7 flex justify-center pl-1">
              <Exit height="24" width="24" />
            </div>
            Logout
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { showVueToast } from '@/utils'
import VueCookies from 'vue-cookies'
export default {
  components: {
    User: () => import(/* webpackChunkName: "icons" */ '@/components/icons/User'),
    Mail: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Mail'),
    Call: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Call'),
    Bootcamp: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Bootcamp'),
    Lock: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Lock'),
    Exit: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/Exit')
  },
  data() {
    return {
      isEditImageVisible: false,
      urlImage: '',
      joinDate: '',
      name: '',
      email: '',
      phoneNumber: '',
      description: ''
    }
  },
  mounted() {
    this.getStudentInformation()
  },
  computed: {
    ...mapGetters('prakerja', ['userProfile']),
    studentId() {
      return this.userProfile?.student?.id
    },
    customerEmployeeId() {
      return parseInt(localStorage.getItem('customer_employee'))
    }
  },
  methods: {
    ...mapActions('student', ['uploadImage', 'updateStudent']),
    ...mapActions('common', ['showLoading', 'hideLoading', 'setPhotoChanged']),
    ...mapActions('prakerja', ['getUserProfile']),
    ...mapActions('auth', ['removeUserData']),
    formatDate(value) {
      return dayjs(value).format('DD-MM-YYYY')
    },
    getStudentInformation() {
      this.showLoading()
      this.getUserProfile().then((res) => {
        this.name = res.name
        this.email = res.email
        this.phoneNumber = res?.hp || ''
        this.description = res?.student?.description || ''
        localStorage.setItem('student_id', res?.student?.id)
        localStorage.setItem('photo', res.student?.photo)
        this.setPhotoChanged({ data: res.student?.photo })
        this.hideLoading()
        this.urlImage = res.student?.photo
        this.joinDate = res?.student?.createdDate && dayjs(res?.student?.createdDate).format('DD-MM-YYYY')
      })
    },
    selectImage(event) {
      if (event.target.files[0]) {
        const selectedFiles = event.target.files[0]
        const nameFile = event.target.files[0].name
        const fileImage = new FormData()
        fileImage.append('file', selectedFiles)
        fileImage.append('name', nameFile)

        this.uploadImage({ data: fileImage }).then(
          (response) => {
            this.urlImage = response.url
            localStorage.setItem('photo', response.url)
            this.updateProfile('photo')
            this.setPhotoChanged({ data: response.url })
          },
          () => {
            this.hideLoading()
          }
        )
      }
    },
    updateProfile(item) {
      this.showLoading()
      const payloads = {
        description: this.description,
        phoneNumber: this.phoneNumber,
        photo: this.urlImage,
        name: this.name
      }
      this.updateStudent({ studentId: this.studentId, payloads }).then(
        () => {
          let alert = ''
          switch (item) {
            case 'description':
              alert = 'Description'
              break
            case 'phone':
              alert = 'Phone Number'
              break
            case 'profile':
              alert = 'Profile'
              this.toggleEditModal()
              break
            default:
              alert = 'Profile Photo'
          }
          this.hideLoading()
          showVueToast(`${alert} is updated successfully!`, 'success', 3000)
        },
        () => {
          this.hideLoading()
        }
      )
    },
    doLogout() {
      VueCookies.remove('token')
      const cleanUserData = new Promise((resolve, reject) => {
        resolve(this.removeUserData())
      })
      cleanUserData.then(() => {
        this.$router.push({ name: 'Home' })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.hero {
  background-image: url('~@/assets/images/profile-cover.webp');
  padding-bottom: 25%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.profpic {
  outline: 4px solid #fff;
}
.router-link-active {
  color: #f7931e;
  background-color: #fffaf5;
  span {
    color: #f7931e;
  }
}
</style>
